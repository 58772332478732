import { GatsbyLinkProps } from 'gatsby';

import { localizeUrl } from 'settings/i18n';
import useCurrentLocale from './useCurrentLocale';

function useLinkCreator(): (
  url: GatsbyLinkProps<{}>['to'],
) => ReturnType<typeof localizeUrl> {
  const currentLocale = useCurrentLocale();
  const regex = /^https?:\/\//i;

  return (url) => (!regex.test(url) ? localizeUrl(currentLocale, url) : url);
}

export default useLinkCreator;
