import { PageProps } from 'gatsby';
import { useLanguages, Language, DEFAULT_LOCALE } from 'settings/i18n';
import { useLocation } from 'hooks/useLocation';

function getCurrentLocale(
  pathname: PageProps['location']['pathname'],
  languages: Language[],
) {
  return (
    languages.find((language) => pathname.startsWith(`/${language.locale}`))
      ?.locale ?? DEFAULT_LOCALE
  );
}

function useCurrentLocale() {
  const languages = useLanguages();
  const location = useLocation();

  return getCurrentLocale(location.pathname, languages);
}

export function useIsRtl(): boolean {
  const locale = useCurrentLocale();

  return locale === 'ar';
}

export default useCurrentLocale;
